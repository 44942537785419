export function copyImageToClipboard(canvas) {
    const img = document.createElement('img');
    img.src = canvas.toDataURL();

    const div = document.createElement('div');
    div.contentEditable = "true";
    div.appendChild(img);
    document.body.appendChild(div);
    selectText(div);
    document.execCommand('Copy');
    document.body.removeChild(div);
}

export function downloadImage(canvas, title) {
    const a = document.createElement("a");
    a.href = canvas.toDataURL();
    a.download = title;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function selectText(element) {
    if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}
