import React from "react";
import {FileCopy, GetApp, Image} from '@material-ui/icons';
import {Tooltip} from "@material-ui/core";
import './ImageControls.css';

export function ImageControls(props) {
    return (
        <div className="imageControls">
            <Tooltip title="Bild anzeigen">
                <Image onClick={() => props.onClick('preview')}/>
            </Tooltip>
            <Tooltip title="Bild Herunterladen">
                <GetApp onClick={() => props.onClick('download')}/>
            </Tooltip>
            <Tooltip title="Bild in die Zwischenablage kopieren">
                <FileCopy onClick={() => props.onClick('copy')}/>
            </Tooltip>
        </div>
    );
}
