import * as React from "react";
import './IconSelector.css';

export class IconSelector extends React.Component {

    knownIcons = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        201
    ];

    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedIcon: 0
        }
    }

    render() {
        const icons = [];
        for (let i = 0; i < this.knownIcons.length; i++) {
            let imgUrl = '/knots/' + this.knownIcons[i] + '.png';
            icons.push(
                <div
                    key={i}
                    className={'icon' + (this.state.selectedIcon === i ? ' selected' : '')}
                    onClick={() => this.handleIconSelected(i)}
                    style={{
                        backgroundImage: `url(${imgUrl})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                </div>
            );
        }
        return (
            <div className="iconSelector">
                {icons}
            </div>
        );
    }

    handleIconSelected(i) {
        this.setState({selectedIcon: i});
        this.props.onIconSelected(this.knownIcons[i]);
    }
}
