import * as React from "react";
import './KnitBoard.css';
import {ImageControls} from "../image/ImageControls";
import {Title} from "./Title";
import {RowNumbers} from "./RowNumbers";
import {Grid} from "./Grid";

export function KnitBoard(props) {

    return (
        <div>
            <div>
                <Title title={props.boardTitle} onTitleChange={(title) => props.onTitleChange(title)}/>
                <div id="gridContainer">
                    <RowNumbers count={props.board.length} size={props.knotSize}/>
                    <Grid
                        grid={props.grid}
                        knotSize={props.knotSize}
                        drawingKnot={props.drawingKnot}
                        onBoardChange={props.onBoardChange}
                    />
                    <RowNumbers count={props.board.length} size={props.knotSize}/>
                </div>
            </div>
            <div className="boardImageControls">
                <ImageControls
                    onClick={(action) => props.onCreateImage(action, 'board', props.boardTitle)}/>
            </div>
        </div>
    );
}

