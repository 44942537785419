import * as React from "react";
import {Undo, Redo, Clear, Remove, Add, ZoomIn, ZoomOut, PageviewOutlined} from '@material-ui/icons';
import './Toolbar.css';
import {Slider, Tooltip} from "@material-ui/core";

export class Toolbar extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            resizeOpen: false,
        }
    }

    render() {
        return (
            <div className="toolbar">
                <Tooltip title="Rückgängig">
                    <div className={'item ' + (this.props.state.canUndo ? '' : 'disabled')}
                         onClick={() => this.props.onUndo()}>
                        <Undo/>
                    </div>
                </Tooltip>
                <Tooltip title="Wiederholen">
                    <div className={'item ' + (this.props.state.canRedo ? '' : 'disabled')}
                         onClick={() => this.props.onRedo()}>
                        <Redo/>
                    </div>
                </Tooltip>
                <Tooltip title="Eine Masche weniger">
                    <div className="changeSizeBtnl" onClick={() => this.handleChangeWidth(-1)}>
                        <Remove fontSize="small"/>
                    </div>
                </Tooltip>
                <Tooltip title="Maschen">
                    <div className="item-input">
                        <input type="number" min="1" max="50" value={this.props.boardWidth}
                               onKeyDown={(e) => this.handleWidthKeyDown(e)}
                               onChange={(e) => this.handleWidthChange(e)}/>
                    </div>
                </Tooltip>
                <Tooltip title="Eine Masche mehr">
                    <div className="changeSizeBtnr" onClick={() => this.handleChangeWidth(1)}>
                        <Add fontSize="small"/>
                    </div>
                </Tooltip>
                <Clear/>
                <Tooltip title="Eine Reihe weniger">
                    <div className="changeSizeBtnl" onClick={() => this.handleChangeHeight(-1)}>
                        <Remove fontSize="small"/>
                    </div>
                </Tooltip>
                <Tooltip title="Reihen">
                    <div className="item-input">
                        <input type="number" min="1" max="50" value={this.props.boardHeight}
                               onKeyDown={(e) => this.handleHeightKeyDown(e)}
                               onChange={(e) => this.handleHeightChange(e)}/>
                    </div>
                </Tooltip>
                <Tooltip title="Eine Reihe mehr">
                    <div className="changeSizeBtnr" style={{marginRight: "5px"}} onClick={() => this.handleChangeHeight(1)}>
                        <Add fontSize="small"/>
                    </div>
                </Tooltip>
                <Tooltip title="Verkleinern">
                    <div className="item" onClick={() => this.props.onZoomOut()}>
                        <ZoomOut />
                    </div>
                </Tooltip>
                <Tooltip title="Rastergröße">
                    <div style={{width: '90px', marginRight: '5px'}}>
                        <Slider value={this.props.knotSize} onChange={(e, v) => this.props.onKnotSizeChange(v)} min={10} max={40}/>
                    </div>
                </Tooltip>
                <Tooltip title="Vergrößern">
                    <div className="item" onClick={() => this.props.onZoomIn()}>
                        <ZoomIn />
                    </div>
                </Tooltip>
                <Tooltip title="Muster-Vorschau">
                    <div className="item button-item" onClick={() => this.props.onShowPreview()}>
                        <PageviewOutlined/><span>Muster-Vorschau</span>
                    </div>
                </Tooltip>
            </div>
        );
    }

    handleWidthChange(e) {
        this.props.onBoardWidthChange(+e.target.value);
        // this.setState({boardWidth: +e.target.value})
    }

    handleHeightChange(e) {
        this.props.onBoardHeightChange(+e.target.value);
        // this.setState({boardHeight: +e.target.value})
    }

    handleWidthKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.onBoardSizeChange(+e.target.value, this.props.boardHeight);
        }
    }

    handleHeightKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.onBoardSizeChange(this.props.boardWidth, +e.target.value);
        }
    }

    handleChangeWidth(diff) {
        const w = this.props.boardWidth + diff;
        // this.setState({boardWidth: w});
        this.props.onBoardSizeChange(w, this.props.boardHeight);
    }

    handleChangeHeight(diff) {
        const h = this.props.boardHeight + diff;
        // this.setState({boardHeight: h});
        this.props.onBoardSizeChange(this.props.boardWidth, h);
    }

}
