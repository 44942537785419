import * as React from "react";
import {PreviewKnot} from "./PreviewKnot";

export function PreviewGrid(props) {
    let knots = [];
    let idx = 0;
    /** flags for expanded knots **/
    let coolDownR = 0; // if > 0, dont draw right border
    let coolDownL = 0; // if > 0, dont draw left border

    for (let r = 0; r < props.grid.length; r++) {
        for (let c = 0; c < props.grid[r].length; c++) {
            if (props.grid[r][c].width > 1) {
                coolDownR = props.grid[r][c].width - 1;
            }
            knots.push(
                <PreviewKnot
                    key={idx++}
                    value={props.grid[r][c]}
                    size={props.knotSize}
                    top={props.knotSize * r}
                    left={props.knotSize * c}
                    noLeftBorder={coolDownL > 0}
                    noRightBorder={coolDownR > 0}
                />
            );
            coolDownL--;
            coolDownR--;
            if (props.grid[r][c].width > 1) {
                coolDownL = props.grid[r][c].width - 1;
            }
        }
    }

    return (
        <div
            className="previewGrid"
            style={{
                position: 'relative',
                width: props.knotSize * props.grid[0].length + 'px',
                height: props.knotSize * props.grid.length + 'px'
            }}
        >
            {knots}
        </div>
    );
}
