import * as React from "react";

export function Knot(props) {
    return (
        <div
            className={'knot' + (props.highlight ? ' highlight' : '')}
            style={{
                boxSizing: 'content-box',
                borderTop: (props.dividerTop ? 2 : 1) + 'px solid ' + (props.dividerTop ? '#999' : '#d3d3d3'),
                borderBottom: '1px solid #d3d3d3',
                borderLeft: (props.noLeftBorder ? 0 : props.dividerLeft ? 2 : 1) + 'px solid ' + (props.dividerLeft ? '#999' : '#d3d3d3'),
                borderRight: (props.noRightBorder ? 0 : 1) + 'px solid #d3d3d3',
                position: 'absolute',
                width: props.size,
                height: props.size,
                top: props.top - (props.dividerTop ? 1 : 0) + 'px',
                left: props.left - (props.dividerLeft ? 1 : 0) + 'px',
            }}
            onMouseDown={(event) => props.onMouseDown(event.button)}
            onMouseUp={(event) => props.onMouseUp(event.button)}
            onMouseEnter={() => props.onMouseEnter()}
        >
            {props.value.icon > 0 &&
            <img
                alt=""
                style={{
                    width: props.size * props.value.width + 'px',
                    height: props.size + 'px',
                }}
                className="no-select"
                width={props.size * props.value.width}
                height={props.size}
                src={`/knots/${props.value.icon}.png`}
            />
            }
        </div>
    );
}
