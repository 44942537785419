import * as React from "react";

export function PreviewKnot(props) {
    return (
        <div
            className="previewKnot"
            style={{
                boxSizing: 'border-box',
                borderTop: '1px solid #d3d3d3',
                borderBottom: '1px solid #d3d3d3',
                borderLeft: (props.noLeftBorder ? 0 : 1) + 'px solid #d3d3d3',
                borderRight: (props.noRightBorder ? 0 : 1) + 'px solid #d3d3d3',
                position: 'absolute',
                width: props.size,
                height: props.size,
                top: props.top + 'px',
                left: props.left + 'px',
            }}
        >
            {props.value.icon > 0 &&
            <img
                alt=""
                style={{
                    width: props.size * props.value.width + 'px',
                    height: props.size + 'px',
                }}
                className="no-select"
                width={props.size * props.value.width}
                height={props.size}
                src={`/knots/${props.value.icon}.png`}
            />
            }
        </div>
    );
}
