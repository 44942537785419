import * as React from "react";

export function RowNumbers(props) {
    let numbers = [];
    for (let i = props.count; i > 0; i--) {
        numbers.push(
            <div
                key={i}
                className="rowNumber"
                style={{
                    height: props.size + 'px',
                    fontSize: props.size / 35 + 'em',
                }}
            >{i}</div>
        )
    }
    return (
        <div>
            {numbers}
        </div>
    );
}
