import * as React from 'react';
import './ToolsPanel.css';
import {Tooltip} from '@material-ui/core';
import {Create, ViewComfy, Remove, RadioButtonUnchecked} from '@material-ui/icons';

export function ToolsPanel(props) {
    return (
        <div className="tools-panel">
            <Tooltip title="Stift">
                <Create/>
            </Tooltip>
            <Tooltip title="Fläche">
                <ViewComfy/>
            </Tooltip>
            <Tooltip title="Linie">
                <Remove/>
            </Tooltip>
            <Tooltip title="Kreis">
                <RadioButtonUnchecked/>
            </Tooltip>
        </div>
    );
}
