import * as React from "react";
import './MenuBar.css';
import {AppBar, Toolbar, Menu, MenuItem, ListItemIcon, Typography} from "@material-ui/core";
import {OpenInBrowser, SaveAlt, PostAdd} from '@material-ui/icons';

export class MenuBar extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            anchorEl: null,
            openMenu: null,
        }
    }

    render() {
        return (
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <div className="menu" onClick={(event) => this.handleOpen('file', event.currentTarget)}>Datei</div>
                    <div className="menu">Bearbeiten</div>
                </Toolbar>
                <Menu
                    id="file-menu"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    open={this.state.openMenu === 'file'}
                    onClose={() => this.handleClose()}
                >
                    <MenuItem onClick={() => this.handleFileNew()}>
                        <ListItemIcon>
                            <PostAdd fontSize="small"/>
                        </ListItemIcon>
                        <Typography variant="inherit">Neues Muster...</Typography>
                    </MenuItem>
                    <MenuItem onClick={(e) => this.handleFileOpen(e)}>
                        <ListItemIcon>
                            <OpenInBrowser fontSize="small"/>
                        </ListItemIcon>
                        <Typography variant="inherit">Datei öffnen...</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.handleFileSave()}>
                        <ListItemIcon>
                            <SaveAlt fontSize="small"/>
                        </ListItemIcon>
                        <Typography variant="inherit">Muster speichern</Typography>
                    </MenuItem>
                </Menu>
            </AppBar>
        );
    }

    handleOpen(menu, anchorEl) {
        this.setState({openMenu: menu, anchorEl: anchorEl});
    }

    handleClose() {
        this.setState({openMenu: null, anchorEl: null});
    }

    handleFileNew() {
        this.handleClose();
    }

    handleFileOpen() {
        this.handleClose();
        this.props.onUploadBoard();
    }

    handleFileSave() {
        this.handleClose();
        this.props.onDownloadBoard();
    }
}

