import * as React from "react";
import './LegendPrint.css';
import './Legend.css';

export function LegendPrint(props) {

    return (
        <div>
            <div id="legend-p">
                <div className="title">Legende</div>
                {props.entries.map(e =>
                    <div key={e.id} className="entry-p">
                        <div
                            className="entryIcon"
                            style={e.icon > 0 ? {
                                backgroundImage: `url(${'/knots/' + e.icon + '.png'})`,
                                backgroundPosition: 'center center',
                                backgroundRepeat: 'no-repeat'
                            } : {}}
                        />
                        <div className="entryText-p">{e.text}&nbsp;</div>
                    </div>
                )}
            </div>
        </div>
    );
}
