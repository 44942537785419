import * as React from "react";

export class Title extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            editing: false,
            title: this.props.title
        }
    }

    render() {
        return (
            <div className="boardTitle">
                <div className={'label' + (this.state.editing ? ' hidden' : '')}
                     onClick={() => this.handleClick()}>{this.props.title}</div>
                <input
                    ref={(input) => this.titleInput = input}
                    className={'input' + (!this.state.editing ? ' hidden' : '')}
                    value={this.state.title}
                    onChange={(e) => this.onTitleChange(e)}
                    onKeyDown={(e) => this.onTitleKeyDown(e)}
                    onBlur={() => this.setState({editing: false})}
                />
            </div>
        )
    }

    onTitleKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.onTitleChange(e.target.value);
            this.setState({editing: false});
        } else if (e.key === 'Escape') {
            this.setState({editing: false});
        }
    }

    onTitleChange(e) {
        this.setState({title: e.target.value});
    }

    handleClick() {
        this.setState({editing: true});
        this.titleInput.focus()
    }
}
