import * as React from "react";
import {copyGrid, defaultKnot} from "../../grid-tools";
import {Knot} from "./Knot";

export class Grid extends React.Component {

    func = 'grid';

    constructor(props, context) {
        super(props, context);
        this.state = {
            dragging: false,
            highlight: {c1: -1, r1: -1, c2: -1, r2: -1},
            highlightArea: [],
        }
    }

    render() {
        let knots = [];
        let idx = 0; // knot key
        /** flags for expanded knots **/
        let coolDownR = 0; // if > 0, dont draw right border
        let coolDownL = 0; // if > 0, dont draw left border
        let highlightRange = 0; // highlight range  of the expanded knot
        let anchor = 0; // indicates if the current knot has an anchor and should actually be highlighted
        /** **/
        for (let r = 0; r < this.props.grid.length; r++) {
            let dividerTop = r > 0 && (this.props.grid.length - r) % 5 === 0;
            for (let c = 0; c < this.props.grid[r].length; c++) {
                let dividerLeft = c > 0 && (this.props.grid[r].length - c) % 5 === 0;
                let highlightCurrent = this.calculateHighlight(c, r);
                /** check if we are hovering over an expanded knot and reset the anchor and its siblings if so **/
                if (this.props.grid[r][c].anchor && highlightCurrent) {
                    let ca = this.props.grid[r][c].anchor.c;
                    let idxa = idx - c + ca;
                    for (let i = idxa, ci = ca; i < idx; i++, ci++) {
                        knots[i] = {
                            ...knots[i],
                            props: {
                                ...knots[i].props,
                                value: (this.state.deleting || !this.calculateHighlight(ci, r)) ? defaultKnot() : this.props.drawingKnot,
                                noLeftBorder: false,
                                noRightBorder: false
                            }
                        };
                    }
                    coolDownL = coolDownR = 0;
                }
                /** calculate the actual knot to display and anchor and expanded knot flags**/
                let effectiveKnot = defaultKnot();
                highlightRange = highlightCurrent ? this.props.drawingKnot.width : highlightRange;
                if (!anchor && !this.state.deleting && highlightCurrent) {
                    effectiveKnot = this.props.drawingKnot;
                    anchor = this.props.drawingKnot.width;
                } else if (!highlightRange) {
                    effectiveKnot = this.props.grid[r][c];
                } else if (this.state.deleting) {
                    anchor = highlightRange = 1
                }
                if (effectiveKnot.width > 1) {
                    coolDownR = effectiveKnot.width - 1;
                }
                /** add the new knot to the array **/
                knots.push(
                    <Knot
                        key={idx++}
                        value={effectiveKnot}
                        size={this.props.knotSize}
                        top={this.props.knotSize * r}
                        left={this.props.knotSize * c}
                        highlight={highlightRange && anchor}
                        noLeftBorder={coolDownL > 0}
                        noRightBorder={coolDownR > 0}
                        dividerTop={dividerTop}
                        dividerLeft={dividerLeft}
                        onMouseDown={(button) => this.handleMouseDown(c, r, button)}
                        onMouseUp={(button) => this.handleMouseUp(c, r, button)}
                        onMouseEnter={() => this.handleMouseEnter(c, r)}
                    />
                );
                coolDownL--;
                coolDownR--;
                highlightRange = Math.max(0, highlightRange - 1);
                anchor = Math.max(0, anchor - 1);
                if (effectiveKnot.width > 1) {
                    coolDownL = effectiveKnot.width - 1;
                }
            }
        }
        return (
            <div
                className="grid"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
                onMouseLeave={() => this.setState({
                    dragging: false,
                    dragStartCol: undefined,
                    dragStartRow: undefined,
                    highlight: {c1: -1, c2: -1, r1: -1, r2: -1}
                })}
                style={{
                    position: 'relative',
                    width: this.props.knotSize * this.props.grid[0].length + 2 + 'px',
                    height: this.props.knotSize * this.props.grid.length + 2+ 'px'
                }}>
                {knots}
            </div>
        );
    }

    setHighlightArea(c0, r0, c1, r1) {
        switch(this.func) {
            case 'line':
                this.setState({highlightArea: this.line(c0, r0, c1, r1)});
                break;
            case 'circle':
                this.setState({highlightArea: this.circle(c0, r0, c1, r1)});
                break;
            default:
                break;
        }
    }

    calculateHighlight(c, r) {
        return (
            (this.state.deleting || (c + this.props.drawingKnot.width <= this.props.grid[r].length))
                ? this.isInDrawFunction(c, r)
                : false
        );
    }

    isInDrawFunction(c, r) {

        if(!this.state.dragging && c === this.state.highlight.c1 && r === this.state.highlight.r1) {
            return true;
        }

        switch (this.func) {
            case 'line':
            case 'circle':
                return this.state.highlightArea.find(pixel => pixel[0] === c && pixel[1] === r);
            case 'grid':
                return c >= this.state.highlight.c1 && c <= this.state.highlight.c2 && r >= this.state.highlight.r1 && r <= this.state.highlight.r2;
            case 'rect':
                return ((c === this.state.highlight.c1 || c === this.state.highlight.c2) && r >= this.state.highlight.r1 && r <= this.state.highlight.r2) ||
                    ((r === this.state.highlight.r1 || r === this.state.highlight.r2) && c >= this.state.highlight.c1 && c <= this.state.highlight.c2)
            default:
                return false;
        }
    }

    line(x0, y0, x1, y1) {
        let dx = Math.abs(x1 - x0);
        let dy = Math.abs(y1 - y0);
        let sx = (x0 < x1) ? 1 : -1;
        let sy = (y0 < y1) ? 1 : -1;
        let err = dx - dy;
        let pixels = [];

        while(true) {
            pixels.push([x0, y0]);
            if ((x0 === x1) && (y0 === y1)) break;
            let e2 = 2*err;
            if (e2 > -dy) { err -= dy; x0  += sx; }
            if (e2 < dx) { err += dx; y0  += sy; }
        }
        return pixels;
    }

    circle(x0, y0, x1, y1) {
        let circle = [];
        let cx = x0, cy = y0;
        let r = Math.max(Math.abs(x1 - x0), Math.abs(y1 - y0));
        let f = 1 - r, ddF_x = 0, ddF_y = -2 * r;
        let x = 0;
        let y = r;
        circle.push([cx, cy + r]);
        circle.push([cx, cy - r]);
        circle.push([cx + r, cy]);
        circle.push([cx - r, cy]);

        while(x < y) {
            if(f >= 0) {
                y--;
                ddF_y += 2;
                f += ddF_y;
            }
            x++;
            ddF_x += 2;
            f += ddF_x + 1;
            circle.push([cx + x, cy + y]);
            circle.push([cx - x, cy + y]);
            circle.push([cx + x, cy - y]);
            circle.push([cx - x, cy - y]);
            circle.push([cx + y, cy + x]);
            circle.push([cx - y, cy + x]);
            circle.push([cx + y, cy - x]);
            circle.push([cx - y, cy - x]);
        }
        return circle;
/*
        let circle = [];
        let cx = x0, cy = y0;
        let r = Math.max(Math.abs(x1 - x0), Math.abs(y1 - y0));
        console.log(cx, cy, r);
        for(let a = 0; a <= 270; a+=10) {
            let x = Math.round(0.02 + cx + (r * Math.cos(a)));
            let y = Math.round(0.02 + cy + (r * Math.sin(a)));
            if(!circle.includes([x,y])) {
                circle.push([x,y]);
            }
        }
        return circle;
*/
    }

    handleMouseDown(colIndex, rowIndex, button) {
        this.setState({dragStartCol: colIndex, dragStartRow: rowIndex, dragging: true, deleting: button !== 0, highlightArea: [[colIndex, rowIndex]]});
    }

    handleMouseUp(colIndex, rowIndex, button) {
        if (this.state.dragStartCol >= 0 && this.state.dragStartRow >= 0) {
            const c1 = Math.min(this.state.dragStartCol, colIndex);
            const c2 = Math.max(this.state.dragStartCol, colIndex);
            const r1 = Math.min(this.state.dragStartRow, rowIndex);
            const r2 = Math.max(this.state.dragStartRow, rowIndex);
            this.setHighlightArea(this.state.dragStartCol, this.state.dragStartRow, colIndex, rowIndex);
            this.updateKnots(c1, r1, c2, r2, (button === 0) ? this.props.drawingKnot : defaultKnot());
        }
        this.setState({
            dragging: false,
            deleting: false,
            highlight: {c1: colIndex, r1: rowIndex, c2: colIndex, r2: rowIndex},
            highlightArea: []
        });
    }

    handleMouseEnter(colIndex, rowIndex) {
        if (this.state.dragging) {
            this.setHighlightArea(this.state.dragStartCol, this.state.dragStartRow, colIndex, rowIndex);
            const c1 = Math.min(this.state.dragStartCol, colIndex);
            const c2 = Math.max(this.state.dragStartCol, colIndex);
            const r1 = Math.min(this.state.dragStartRow, rowIndex);
            const r2 = Math.max(this.state.dragStartRow, rowIndex);
            this.setState({highlight: {c1: c1, r1: r1, c2: c2, r2: r2}});
        } else {
            this.setState({highlight: {c1: colIndex, r1: rowIndex, c2: colIndex, r2: rowIndex}});
        }
    }

    updateKnots(c1, r1, c2, r2, knot) {
        let changed = false;
        const grid = copyGrid(this.props.grid);
        for (let r = r1; r <= r2; r++) {
            let anchorCoolDown = 0; // flag indicating that a new expanded knot is set
            for (let c = c1; c <= c2; c++) {
                /** draw on an knot with existing anchor: remove anchor knot and all siblings**/
                if (anchorCoolDown === 0 && grid[r][c].anchor && this.calculateHighlight(c, r)) {
                    let ca = grid[r][c].anchor.c;
                    let ce = ca + grid[r][ca].width;
                    for (let ci = ca; ci < ce; ci++) {
                        grid[r][ci] = {...defaultKnot()};
                        changed = true;
                    }
                }
                /** draw on an exended knot: remove this one and all siblings **/
                if (grid[r][c].width > 1) {
                    for (let i = 1; i < grid[r][c].width; i++) {
                        grid[r][c + i] = {...defaultKnot()};
                        changed = true;
                    }
                }
                /** draw the new knot **/
                if (!grid[r][c].anchor && this.calculateHighlight(c, r) && grid[r][c].id !== knot.id) {
                    grid[r][c] = {...knot};
                    anchorCoolDown = knot.width;
                    for (let i = 1; i < knot.width; i++) {
                        /** new knot extends over an extended knot: remove all of its anchors **/
                        if (grid[r][c + i].width > 1) {
                            let w = grid[r][c + i].width;
                            for (let j = c + i; j < c + i + w; j++) {
                                grid[r][j] = {...defaultKnot()}
                            }
                        }
                        /** set anchors if we are drawing an extended knot **/
                        grid[r][c + i] = {...defaultKnot(), anchor: {r: r, c: c}}
                    }
                    changed = true;
                }
                anchorCoolDown--;
            }
        }
        if (changed) {
            this.props.onBoardChange(grid);
        }
    }
}
