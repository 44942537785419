export function createGrid(cols, rows) {
    const board = new Array(rows);
    for(let i = 0; i < rows; i++) {
        board[i] = [];
        for(let j = 0; j < cols; j++) {
            board[i].push(defaultKnot());
        }
    }
    return board;
}

export function copyGrid(grid) {
    const copy = [...grid];
    for(let i = 0; i < copy.length; i++) {
        copy[i] = [...grid[i]];
    }
    return copy;
}

export function resizeGrid(grid, cols, rows) {
    grid = copyGrid(grid);
    if (rows < grid.length) {
        grid = grid.slice(grid.length - rows);
    } else {
        const diff = rows - grid.length;
        for(let r = 0; r < diff; r++) {
            pushRow(grid);
        }
    }
    if (cols < grid[0].length) {
        for (let r = 0; r < grid.length; r++) {
            grid[r] = grid[r].slice(grid[r].length - cols);
        }
    } else {
        grid.forEach(row => {
            const diff = cols - row.length;
            for(let c = 0; c < diff; c++) {
                row.unshift(defaultKnot());
            }
        });
    }
    return grid;
}

export function pushRow(grid) {
    const row = [];
    for (let i = 0; i < grid[0].length; i++) {
        row.push(defaultKnot());
    }
    grid.unshift(row);
}

export const defaultKnot = () => ({id: 1, icon: 0, width: 1});

