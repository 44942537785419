import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {Preview} from "./Preiview";

export function PreviewDialog(props) {

    return (
        <Dialog maxWidth="xl" open={props.open} onClose={() => props.onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Muster Vorschau</DialogTitle>
            <DialogContent>
                <Preview
                    grid={props.grid}
                    knotSize={15}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
