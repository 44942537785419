import * as React from "react";
import './KnitApp.css';
import {Toolbar} from "./components/toolbar/Toolbar";
import {Legend} from "./components/legend/Legend";
import html2canvas from "html2canvas";
import {ImagePreview} from "./components/image/ImagePreview";
import {copyImageToClipboard, downloadImage} from "./components/image/image-tools";
import {PreviewDialog} from "./components/preview/PreviewDialog";
import {ToolsPanel} from "./components/toolspanel/ToolsPanel";
import {MenuBar} from "./components/menu/MenuBar";
import {saveAs} from 'file-saver';
import fileDialog from 'file-dialog'
import {createGrid, defaultKnot, resizeGrid} from "./grid-tools";
import {KnitBoard} from "./components/board/KnitBoard";

export default class KnitApp extends React.Component {

    DEFAULT_ROWS = 15;
    DEFAULT_KNOTS = 10;


    constructor(props, context) {
        super(props, context);
        this.state = this.initState(this.initBoard(this.DEFAULT_KNOTS, this.DEFAULT_ROWS));
    }

    initState(board) {
        return {
            history: [board],
            boardWidth: board.grid[0].length,
            boardHeight: board.grid.length,
            historyPos: 0,
            selectedKnot: {...board.legend[0]},
            knotSize: 25,
            showImagePreview: false,
            showBoardPreview: false,
        };
    }

    initBoard(cols, rows) {
        return {
            grid: createGrid(cols, rows),
            title: 'Strickmuster',
            legend: [
                {...defaultKnot(), fixed: true},
                {...defaultKnot(), id: 2, icon: 1, width: 1, text: `test`},
            ],
        };
    }

    render() {
        return (
            <div id="KnitApp">
                <MenuBar
                    onDownloadBoard={() => this.handleDownloadBoard()}
                    onUploadBoard={() => this.handleUploadBoard()}
                />
                <div style={{marginTop: '50px'}}>
                    <div tabIndex="0" id="WorkArea" onKeyDown={(event) => this.handleKeyPress(event.nativeEvent)}>
                        <Toolbar
                            onUndo={() => this.back()}
                            onRedo={() => this.forward()}
                            onBoardWidthChange={(width) => this.setState({boardWidth: width})}
                            onBoardHeightChange={(height) => this.setState({boardHeight: height})}
                            onBoardSizeChange={(cols, rows) => this.handleResizeGrid(cols, rows)}
                            onZoomIn={() => this.setState({knotSize: this.state.knotSize < 40 ? this.state.knotSize + 1 : this.state.knotSize})}
                            onZoomOut={() => this.setState({knotSize: this.state.knotSize > 10 ? this.state.knotSize - 1 : this.state.knotSize})}
                            onKnotSizeChange={(size) => this.setState({knotSize: size})}
                            onShowPreview={() => this.setState({showBoardPreview: true})}
                            state={{
                                canUndo: this.state.historyPos > 0,
                                canRedo: this.state.historyPos < this.state.history.length - 1
                            }}
                            boardWidth={this.state.boardWidth}
                            boardHeight={this.state.boardHeight}
                            knotSize={this.state.knotSize}
                        />
                        <div className="mainContent">
                            <ToolsPanel/>
                            <div className="boardPanel">
                                <KnitBoard
                                    grid={this.currentBoard().grid}
                                    board={this.currentBoard().grid}
                                    boardTitle={this.currentBoard().title}
                                    drawingKnot={this.state.selectedKnot}
                                    knotSize={this.state.knotSize}
                                    onBoardChange={(grid) => this.handleGridPropertiesChange({grid: grid})}
                                    onTitleChange={(title) => this.handleGridPropertiesChange({title: title})}
                                    onCreateImage={(action, elementId, title) => this.createImage(action, elementId, title)}
                                    onInsertRow={(index) => this.handleAddRow(index)}
                                />
                                <Legend
                                    legend={this.currentBoard().legend}
                                    selected={this.state.selectedKnot}
                                    onKnotSelected={(knot) => this.setState({selectedKnot: knot})}
                                    onAddEntry={(knot) => this.handleAddEntryToLegend(knot)}
                                    onDeleteEntry={(knot) => this.handleDeleteEntryFromLegend(knot)}
                                    onUpdateEntry={(e) => this.handleUpdateEntryInLegend(e)}
                                    onCreateImage={(action, elementId, title) => this.createImage(action, elementId, title)}
                                />
                            </div>
                        </div>
                        <PreviewDialog
                            grid={this.currentBoard().grid}
                            open={this.state.showBoardPreview}
                            onClose={() => this.setState({showBoardPreview: false})}/>
                        <ImagePreview
                            open={this.state.showImagePreview}
                            width={this.state.previewWidth}
                            height={this.state.previewHeight}
                            data={this.state.previewData}
                            elementId={this.state.previewElementId}
                            title={this.state.previewTitle}
                            onCreateImage={(action, elementId, title) => this.createImage(action, elementId, title)}
                            onClose={() => this.setState({showImagePreview: false})}
                        />
                    </div>
                </div>
            </div>
        );
    }

    /*** history functions ***/
    currentBoard() {
        return this.state.history[this.state.historyPos];
    }

    back() {
        if (this.state.historyPos > 0) {
            this.setState({
                historyPos: this.state.historyPos - 1,
                boardWidth: this.state.history[this.state.historyPos - 1].grid[0].length,
                boardHeight: this.state.history[this.state.historyPos - 1].grid.length
            });
        }
    }

    forward() {
        if (this.state.historyPos < this.state.history.length - 1) {
            this.setState({
                historyPos: this.state.historyPos + 1,
                boardWidth: this.state.history[this.state.historyPos + 1].grid[0].length,
                boardHeight: this.state.history[this.state.historyPos + 1].grid.length
            });
        }
    }

    /*** board updates ***/

    handleGridPropertiesChange(props) {
        const pos = this.state.historyPos + 1;
        const history = this.state.history.slice(0, pos);
        const newEntry = {...history[pos - 1], ...props};
        history.push(newEntry);
        this.setState({history: history, historyPos: pos});

    }

    /** grid **/
    handleResizeGrid(cols, rows) {
        let grid = this.currentBoard().grid;
        if (rows > 0 && cols > 0 && (rows !== grid.length || cols !== grid[0].length)) {
            grid = resizeGrid(grid, cols, rows);
            this.handleGridPropertiesChange({grid: grid});
            this.setState({boardWidth: cols, boardHeight: rows});
        }
    }

    handleAddRow(index) {
        let grid = this.currentBoard().grid;
        if (index >= 0 && index <= grid.length) {
            grid = resizeGrid(grid, grid[0].length, grid.length + 1);
            let row = grid[0];
            for (let i = 0; i < index; i++) {
                grid[i] = grid[i + 1];
            }
            grid[index] = row;
            this.handleGridPropertiesChange({grid: grid});
        }
    }

    /** legend **/
    handleAddEntryToLegend(knot) {
        const legend = [...this.currentBoard().legend];
        legend.push(knot);
        this.handleGridPropertiesChange({legend: legend});
        this.setState({selectedKnot: knot});
    }

    handleDeleteEntryFromLegend(knot) {
        const legend = this.currentBoard().legend.filter(k => k !== knot);
        if (this.state.selectedKnot === knot) {
            let index = this.currentBoard().legend.indexOf(knot);
            if (index >= legend.length) {
                index = legend.length - 1;
            }
            this.setState({selectedKnot: legend[index]});
        }
        this.handleGridPropertiesChange({legend: legend});
    }

    handleUpdateEntryInLegend(knot) {
        const legend = [...this.currentBoard().legend];
        for (let i = 0; i < legend.length; i++) {
            if (legend[i].id === knot.id) {
                if (this.state.selectedKnot === legend[i]) {
                    this.setState({selectedKnot: knot});
                }
                legend[i] = knot;
                break;
            }
        }
        this.handleGridPropertiesChange({legend: legend});
    }

    /*** Controls ***/
    handleKeyPress(e) {
        if (e.ctrlKey) {
            switch (e.key) {
                case 'z':
                    this.back();
                    break;
                case 'y':
                    this.forward();
                    break;
                default:
                    break;
            }
        }
    }

    createImage(action, elementId, title) {
        html2canvas(document.querySelector("#" + elementId)).then(canvas => {
            switch (action) {
                case 'preview':
                    this.setState({
                        showImagePreview: true,
                        previewData: canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height),
                        previewWidth: canvas.width,
                        previewHeight: canvas.height,
                        previewElementId: elementId,
                        previewTitle: title,
                    });
                    break;
                case 'download':
                    downloadImage(canvas, title);
                    break;
                case 'copy':
                    copyImageToClipboard(canvas);
                    break;
                default:
                    break;
            }
        });

    }

    handleDownloadBoard() {
        const blob = new Blob([JSON.stringify(this.currentBoard())], {type: "application/json;charset=utf-8"});
        saveAs(blob, this.currentBoard().title + '.knit');
    }

    handleUploadBoard() {
        fileDialog({accept: "*.knit"}).then(files => {
            let reader = new FileReader();
            reader.onload = () => {
                let text = reader.result;
                try {
                    let board = JSON.parse('' + text);
                    if (board && board.grid && board.title && board.legend) {
                        this.setState(this.initState(board));
                    } else {
                        console.error("NOT A BOARD!");
                    }
                } catch (e) {
                    console.error(e);
                }
            };
            reader.readAsText(files[0]);
        });
    }
}
