import * as React from "react";
import './Legend.css';
import {AddBox, Delete, Edit} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import {Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button} from "@material-ui/core";
import {IconSelector} from "./IconSelector";
import {ImageControls} from "../image/ImageControls";
import {LegendPrint} from "./LegendPrint";
import {defaultKnot} from "../../grid-tools";

export class Legend extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            editedKnot: null,
            editing: false,
            editIcon: null,
            editText: null,
        }
    }

    render() {
        const entries = [];
        for (let i = 0; i < this.props.legend?.length; i++) {
            entries.push(
                <LegendEntry
                    key={this.props.legend[i].id}
                    knot={this.props.legend[i]}
                    selected={this.props.legend[i].id === this.props.selected.id}
                    onEntryClick={(knot) => this.handleEntryClick(knot)}
                    onEditEntry={(knot) => this.handleEditEntry(knot)}
                    onDeleteEntry={(knot) => this.handleDeleteEntry(knot)}
                />
            );
        }
        return (
            <div id="legend" className="legend">
                <div className="legendHeader">
                    <div className="title">Legende</div>
                    <Button
                        color="default"
                        size="small"
                        startIcon={<AddBox/>}
                        onClick={() => this.handleAddEntry()}
                    >neuer Eintrag</Button>
                </div>
                {entries}
                <ImageControls onClick={(action) => this.props.onCreateImage(action, 'legend-p', 'Legende')}/>
                <EditLegendEntryDialog
                    value={this.state.editedKnot}
                    open={this.state.editing}
                    new={this.state.newEntry}
                    onIconSelected={(icon) => this.setState({editedKnot: {...this.state.editedKnot, icon: icon}})}
                    onTextChanged={(text) => this.setState({editedKnot: {...this.state.editedKnot, text: text}})}
                    onClose={() => this.setState({editing: false})}
                    onSave={() => this.handleSaveEntry()}
                />
                <LegendPrint entries={this.props.legend}/>
            </div>
        );
    }

    handleEntryClick(knot) {
        this.props.onKnotSelected(knot);
    }

    handleAddEntry() {
        this.setState({newEntry: true});
        const id = Math.max(...this.props.legend.map(l => l.id)) + 1;
        const knot = {...defaultKnot(), id: id};
        this.setState({editedKnot: knot, editing: true});
    }

    handleDeleteEntry(knot) {
        this.props.onDeleteEntry(knot);
    }

    handleEditEntry(knot) {
        this.setState({editedKnot: {...knot}, editing: true, newEntry: false});
    }

    handleSaveEntry() {
        const knot = {...this.state.editedKnot};
        if(this.state.newEntry) {
            this.props.onAddEntry(knot);
            this.setState({newEntry: false, editing: false});
        } else {
            this.props.onUpdateEntry(knot);
            this.setState({editing: false});
        }
    }
}

export function LegendEntry(props) {
    let imgUrl = '/knots/' + props.knot?.icon + '.png';
    return (
        <div className={'entry' + (props.selected ? ' entrySelected' : '')}>
            <div style={{display: 'inline-flex', alignItems: 'center'}} onClick={() => props.onEntryClick(props.knot)}>
                <div
                    className="entryIcon"
                    style={props.knot?.icon > 0 ? {
                        backgroundImage: `url(${imgUrl})`,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat'
                    } : {}}
                />
                <div className="entryText">{props.knot.text}</div>
            </div>
            <Tooltip title="löschen">
                <div
                    className={'entryDelete' + (props.knot.fixed ? ' entryHidden' : '')}
                    onClick={() => props.onDeleteEntry(props.knot)}
                ><Delete/></div>
            </Tooltip>
            <Tooltip title="bearbeiten">
                <div
                    className="entryEdit"
                    onClick={() => props.onEditEntry(props.knot)}
                ><Edit/></div>
            </Tooltip>
        </div>
    );
}

export function EditLegendEntryDialog(props) {
    let imgUrl = '/knots/' + props.value?.icon + '.png';
    return (
        <Dialog open={props.open} onClose={() => props.onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.new ? 'Neuer Eintrag' : 'Eintrag bearbeiten'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Wähle {!props.value?.fixed && 'ein Symbol und '}einen Text für den Legendeneintrag
                </DialogContentText>
                <div className="entry">
                    <div
                        className="entryIcon"
                        style={{
                            backgroundImage: `url(${imgUrl})`,
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                    <TextField
                        value={props.value?.text}
                        onChange={(e) => props.onTextChanged(e.target.value)}
                    />
                </div>
                {!props.value?.fixed &&
                    <IconSelector onIconSelected={(icon) => props.onIconSelected(icon)}/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={() => props.onSave()} color="primary">
                    Übernehmen
                </Button>
            </DialogActions>
        </Dialog>
    );
}
