import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@material-ui/core";
import {FileCopy, GetApp} from '@material-ui/icons';
import * as React from "react";
import './ImagePreview.css';

export class ImagePreview extends React.Component {

    render() {
        return (
            <Dialog open={this.props.open} onClose={() => this.props.onClose()} onEnter={() => this.handleEnter()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Bild speichern</DialogTitle>
                <DialogContent>
                    <div className="previewDialogContent">
                        <canvas id="image" width={this.props.width} height={this.props.height}/>
                        <div className="controls">
                            <Button
                                color="default"
                                size="small"
                                startIcon={<GetApp/>}
                                onClick={() => this.handleControlClick('download')}
                            >Herunterladen</Button>
                            <Button
                                color="default"
                                size="small"
                                startIcon={<FileCopy/>}
                                onClick={() => this.handleControlClick('copy')}
                            >Kopieren</Button>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose()} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleEnter() {
        const canvas = document.querySelector("#image");
        canvas.getContext('2d').putImageData(this.props.data, 0, 0);
    }

    handleControlClick(action) {
        this.props.onCreateImage(action, this.props.elementId, this.props.title);
    }
}
