import * as React from "react";
import './Preview.css';
import * as shortid from "shortid";
import {PreviewGrid} from "./PreviewGrid";

export function Preview(props) {

    const PREVIEW_COUNT = 3;

    const grids = [];
    for (let i = 0; i < PREVIEW_COUNT; i++) {
        grids.push(
            <PreviewGrid
                key={shortid.generate()} grid={props.grid}
                knotSize={props.knotSize}
            />
        );
    }
    return (
        <div id="preview">
            {grids}
        </div>
    )
}

